import React, { Component } from "react";
import axios from "axios";
// import { motion } from "framer-motion";
import useSound from "use-sound";

import logo from "./image/punkr-logo.svg";
import toggle from "./image/icon-toggle.png";

import Asset from "./Asset";
import Button from "./Button";
import SpinButton from "./SpinButton";
import Wheel from "./Wheel";
import "./App.css";

// import spinHover from "./sound/8bit-Video-Game-Startup-01.m4a";
import spinHover from "./sound/Synth-Pop-Small-01.m4a";
import spinClicked from "./sound/Woodpecker-Peck-01.m4a";

// const colors = ["#FFFFFF", "#F06B4F", "#F2AE52", "#B0CD6D", "#A33120"];

// SOUND

// const SpinButton = () => {
//   const [play, { stop }] = useSound(spinHover);
//   // const [click] = useSound(spinClicked);

//   return (
//     <Button
//       name="Spin"
//       // onClick={(click, () => this.getNewQuote())}
//       onMouseEnter={play}
//       onMouseLeave={stop}
//     />
//   );
// };

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quote: "",
      quote_end: "",
      emoji: "",
      name: "",
      // id: "",
    };
  }

  componentDidMount() {
    this.getQuote();
    this.getPeople();
  }

  // QUOTES

  getQuote() {
    let url = "./data-quotes.json";

    axios.get(url).then((res) => {
      let data = res.data.quotes;
      let quoteNum = Math.floor(Math.random() * data.length);
      let randomQuote = data[quoteNum];

      this.setState({
        quote: randomQuote["quote"],
        quote_end: randomQuote["quote_end"],
        emoji: randomQuote["emoji"],
      });
    });

    this.getNewPeople();
  }

  getNewQuote = () => {
    this.getQuote();
  };

  // PEOPLE

  getPeople() {
    let url = "./data-people.json";

    axios.get(url).then((res) => {
      let data = res.data.people;
      let peopleNum = Math.floor(Math.random() * data.length);
      let randomPeople = data[peopleNum];

      this.setState({
        // people: randomPeople["id"],
        name: randomPeople["name"],
      });
    });
  }

  getNewPeople = () => {
    this.getPeople();
  };

  // setPeople = (e) => {
  //   this.setState({
  //     name: e,
  //   });
  // }

  setPeople = (e) => {
    this.setState({
      name: e,
    });
  };

  // RENDER

  render() {
    const { quote, quote_end, emoji, name } = this.state;

    return (
      <div className="app">
        <Wheel />
        <div className="app-header">
          <div className="app-title">
            <img src={logo} className="app-logo" alt="logo" />
            <div className="app-name">
              DAY
              <br />
              AFTER
              <br />
              LOCKDOWN
              <br />
              <span className="app-subname">Powered by Punkr.</span>
            </div>
          </div>
          <div className="app-toggle">
            <img src={toggle} className="icon-toggle" alt="" />
          </div>
        </div>
        <div className="app-body">
          <Asset quote={quote} quoteEnd={quote_end} emoji={emoji} name={name} />
        </div>

        <div className="app-footer">
          <div className="app-people">
            <Button name="💋" onClick={() => this.setPeople("lover")} />
            <Button name="👱‍♀️" onClick={() => this.setPeople("girlfriend")} />
            <Button name="👨‍" onClick={() => this.setPeople("boyfriend")} />
            <Button name="👧" onClick={() => this.setPeople("sister")} />
            <Button name="👦" onClick={() => this.setPeople("brother")} />
            <Button name="👩" onClick={() => this.setPeople("mom")} />
            <Button name="👨" onClick={() => this.setPeople("dad")} />
            <Button name="🧒" onClick={() => this.setPeople("child")} />
            <Button name="👵" onClick={() => this.setPeople("granny")} />
            <Button name="👴" onClick={() => this.setPeople("grandpa")} />
            {/* <Button name="👵👴🙆🏼‍♂️🙆‍♂️👱‍♀️👨‍🦰👧👦🧒👨👩" onClick={() => this.setPeople("ggg")} /> */}
          </div>
          {/* <Button name="People" width="20rem" onClick={this.getNewPeople} /> */}
          <Button name="Spin" width="20rem" onClick={this.getNewQuote} />
          <SpinButton onClick={this.getNewQuote} />
          <div className="app-social"></div>
        </div>
      </div>
    );
  }
}
