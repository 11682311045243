import React, { Component } from "react";
import { motion } from "framer-motion";

import "./Wheel.css";

// import useSound from "use-sound";
// import boopSfx from "./sound/8bit-Video-Game-Startup-01.m4a";

export default class Wheel extends Component {
  render() {
    // const [play, { stop }] = useSound(boopSfx);

    return (
      <div className="app-wheel">
        <div className="wheel-helper">
          <motion.div
            animate={{
              rotate: [0, 360],
            }}
            className="wheel-body"
          />
        </div>
      </div>
    );
  }
}
