import React, { Component } from "react";
import { motion } from "framer-motion";

import useSound from "use-sound";
import "./SpinButton.css";

// import spinStart from "./sound/8bit-Video-Game-Startup-01.m4a";
import spinHover from "./sound/Synth-Pop-Small-01.m4a";
import spinClicked from "./sound/Woodpecker-Peck-01.m4a";

export default function SpinButton() {
  const [play, { stop }] = useSound(spinHover);
  const [spin] = useSound(spinClicked);
  return (
    <motion.button
      className="spin-button"
      // onClick={t.onClick}
      onClick={spin}
      whileHover={{ translateY: "-4px" }}
      // whileTap={{ scale: 0.95 }}
      onMouseEnter={play}
      onMouseLeave={stop}
      // style={{ width: }}
    >
      Spin — Button
    </motion.button>
  );
}
