import React, { Component } from "react";
import { motion } from "framer-motion";

import "./Button.css";

// import useSound from "use-sound";
// import boopSfx from "./sound/8bit-Video-Game-Startup-01.m4a";

export default class Button extends Component {
  render() {
    // const [play, { stop }] = useSound(boopSfx);
    return (
      <motion.button
        className="button-body"
        onClick={this.props.onClick}
        whileHover={{ translateY: "-4px" }}
        whileTap={{ scale: 0.95 }}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        style={{ width: this.props.width }}
      >
        {this.props.name}
      </motion.button>
    );
  }
}
