import React, { Component } from "react";
import "./Asset.css";

export default class Asset extends Component {
  render() {
    return (
      <div className="asset-body">
        <div className="asset-title">
          {this.props.quote} {this.props.name}
          {this.props.quoteEnd}
        </div>
        <div className="asset-emoji">{this.props.emoji}</div>
      </div>
    );
  }
}
